import CarBrokenIcon from '@comparacar/components/src/icons/CarBroken'
import Box from '@mui/material/Box'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import NextLink from 'next/link'
import React, { ReactElement } from 'react'

import Layout from '../template/Layout'

function Error() {
  return (
    <>
      <Box sx={{ display: 'flex', flexFlow: 'column', alignItems: 'center', justifyContent: 'center', paddingY: 8 }}>
        <Box textAlign="center">
          <Typography variant="h1">404 error</Typography>
          <Typography variant="h5">Página não encontrada</Typography>
        </Box>
        <Box textAlign="center" fontSize="10em">
          <CarBrokenIcon fontSize="inherit" />
        </Box>
        <NextLink href="/" passHref legacyBehavior>
          <Link variant="h6">Faça uma nova pesquisa!</Link>
        </NextLink>
      </Box>
    </>
  )
}

Error.getLayout = function getLayout(page: ReactElement) {
  return <Layout>{page}</Layout>
}

export default Error
